import styled from "styled-components";

export const BaseTextAreaInput = styled.textarea`
    border: 1px solid ${props => props.borderColor};
    border-radius: 5px;
    padding: 7px 24px 8px;
    background-color: #fff;
    min-height: 125px;
`;

export const BaseContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.label`
    margin-bottom: 5px;
    display: flex;
`;

export const AsteriskContainer = styled.div`
    display: ${props => props.display};
    justify-content: center;
    align-items: center;
`;

export const ErrorContainer = styled.div`
    dispaly: ${props => props.display};
`;