import styled from "styled-components";


export const ProfileMenuDiv = styled.div`
   padding: 10px;
`;

export const Profile = styled.div`
    display: flex;
    padding: 10px 5px 17px;
    border-bottom: 1px solid #eeeeee;
    align-items: center;
`;

export const ProfileMenuContainer = styled.div`
    display: block;
    margin-top: 15px;
    margin-inline-start: 20px;
    
    & > :not(:last-child) {
        margin-bottom: 3px;
    }
`;

export const Link = styled.span`
cursor:pointer;
font-family:Heebo;
color: ${props => props.color};
font-size: ${props => props.size};
text-decoration:${props => props.textDecoration};
`;
export const LoguotContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
`;
