import React, { useState, useEffect } from "react";
import Button from "components/common/Button";
import { SignUpContainer, AvatarContainer, SignInContainer } from "./style";
import Avatar from "components/common/Avatar";
import ProfileMenu from "components/features/ProfileMenu";
import { getUserInfo, setCookie } from "Services/Api/index";
import Menu from "components/common/Menu";

const labels = {
  signin: "כניסת לקוחות",
  signup: "הירשמו עכשיו",
};

function Login(props) {
  const [userIsConnected, setUserIsConnected] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = window.innerWidth < 768;
  const textFontWeight = "500";

  useEffect(() => {
    if (!userInfo) {
      getData();
    }

    if (
      window.location.href.includes("utm_source") &&
      !localStorage.getItem("utm_source")
    ) {
      let search = window.location.search;
      let utmParameters = new URLSearchParams(search);
      let params = {
        utm_source: utmParameters.get("utm_source"),
        utm_campaign: utmParameters.get("utm_campaign"),
        utm_content: utmParameters.get("utm_content"),
        utm_network: utmParameters.get("utm_network"),
        utm_medium: utmParameters.get("utm_medium"),
        utm_term: utmParameters.get("utm_term"),
      };
      const response = setCookie(params);
    }
  }, []);

  const getData = async () => {
    const data = await getUserInfo();
    setUserInfo(data);
    setIsLoading(false);
    if (data && data.userName !== "") {
      setUserIsConnected(true);
    }
  };

  const handleAvatarClick = () => {
    if (!isMobile) {
      setOpenProfileMenu(!openProfileMenu);
    } else {
      goToUrl(
        "https://app.powerlink.co.il/workpad/admin/usersettings/userinfo.aspx?objectid=" +
          userInfo.userID
      );
    }
  };
  const goToUrl = (url) => {
    window.location = url;
  };

  return (
    <>
      {isLoading ? (
        <></>
      ) : userIsConnected ? (
        <SignInContainer>
          <Menu
            buttonEl={
              <AvatarContainer onClick={handleAvatarClick}>
                <Avatar src={userInfo.profileImg} size="40px" />
              </AvatarContainer>
            }
          >
            <ProfileMenu
              userImgSrc={userInfo.profileImg}
              fullname={userInfo.fullName}
              userID={userInfo.ID}
              userName={userInfo.userName}
            />
          </Menu>
          <Button
            rounded
            text={labels.signin}
            onClick={() => goToUrl("https://app.powerlink.co.il")}
            backgroundColor="#fff"
            color="#383b51"
            fontWeight={textFontWeight}
          />
        </SignInContainer>
      ) : (
        <SignUpContainer>
          <Button
            rounded
            textSize="14px"
            text={labels.signup}
            onClick={() => goToUrl("https://www.powerlink.co.il/register")}
            backgroundColor="#3d78f8"
            color="#fff"
            fontWeight={textFontWeight}
          />
          <Button
            rounded
            text={labels.signin}
            textSize="14px"
            onClick={() => goToUrl("https://app.powerlink.co.il")}
            backgroundColor="#fff"
            color="#383b51"
            fontWeight={textFontWeight}
          />
        </SignUpContainer>
      )}
    </>
  );
}

export default Login;
