import React from "react";
import PropTypes from "prop-types";
import Card from "components/common/Card";
import Text from "components/common/Text";
import ThankYouImg from "images/joining.png";
import { Image, Container, TextContainer } from "./style";
import { SUCCESS_MSG } from "constant/index";

function ThankYou(props) {
  let isMobile = window.innerWidth < 767;

  return (
    <Container>
      <Card>
        <Image src={`${process.env.REACT_APP_BASE_URL}/${ThankYouImg}`} />
        <TextContainer>
          <Text size={isMobile ? "30px" : "40px"} color="#2d2c4d" bold>
            {SUCCESS_MSG.primaryHeader}
          </Text>
          <Text size={isMobile ? "16px" : "22px"} color="#878787">
            {SUCCESS_MSG.secondaryHeader}
          </Text>
        </TextContainer>
      </Card>
    </Container>
  );
}

export default ThankYou;
