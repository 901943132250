import styled from "styled-components";


export const Image = styled.img`
    max-width: 450px;
    padding-bottom: 32px;
    padding-top: 22px;

    @media screen and (max-width: 767px){
        max-width: 300px;
    }
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1100px;
    width: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    direction: rtl;

    @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-right: 8px;
        margin-left: 8px;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > *:first-child {
        margin-bottom: 10px;
    }
`;