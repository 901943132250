import styled from "styled-components";
import Menu from '@material-ui/core/Menu';

export const SignUpContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
`;

export const SignInContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;

    & > *:first-child {
        margin-inline-start: 15px;
    }

`;

export const BaseMenu = styled(Menu)`
.MuiListItem-button:hover {
    text-decoration: none;
    background-color: transparent;
}

.MuiList-padding {
padding-top: 0px;
padding-bottom: 0px;
}

.MuiPaper-elevation8 {
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.05), 0 4px 3px 0 rgba(0,0,0,0.05);
}
`;

export const AvatarContainer = styled.div`
    cursor: pointer;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline-end: 14px;

`;