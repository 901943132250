import React from "react";
import PropTypes from "prop-types";
import {
  BaseTextAreaInput,
  BaseContainer,
  Header,
  ErrorContainer,
  AsteriskContainer,
} from "./style";
import Text from "components/common/Text";

TextAreaInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showErrorMsg: PropTypes.bool,
  errorMsg: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

function TextAreaInput(props) {
  const handleChange = (event) => {
    if (props.onChange) {
      props.onChange(event.currentTarget.value);
    }
  };
  return (
    <BaseContainer>
      <Header>
        <Text size="14px" bold>
          {props.label}
        </Text>
        {props.required ? (
          <AsteriskContainer display={props.showErrorMsg ? "flex" : "none"}>
            <Text color={"red"} size="14px">
              *
            </Text>
          </AsteriskContainer>
        ) : (
          <></>
        )}
      </Header>
      <BaseTextAreaInput
        placeholder={props.placeholder}
        borderColor={props.showErrorMsg ? "red" : "#e6e8f0"}
        value={props.value}
        onChange={handleChange}
      ></BaseTextAreaInput>
      <ErrorContainer display={props.showErrorMsg ? "flex" : "none"}>
        <Text color={"red"} size="10px">
          {props.errorMsg}
        </Text>
      </ErrorContainer>
    </BaseContainer>
  );
}

export default TextAreaInput;
