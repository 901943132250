import styled from "styled-components";

export const BaseButton = styled.button`
  border-radius: ${(props) => props.radius};
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  color: ${(props) => props.color};
  font-family: Heebo, sans-serif;
  text-align: center;
  text-transform: capitalize;
  border: 1px solid transparent;
  font-size: ${(props) => props.textSize};
  cursor: pointer;
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  font-weight: ${(props) => props.fontWeight};

  &:hover {
    transform: translate(0, -3px);
    box-shadow: 0 6px 12px -2px rgba(61, 120, 248, 0.6);
    transition: border-color 0.4s ease, transform 0.4s ease,
      box-shadow 0.4s ease, -webkit-transform 0.4s ease;
  }
`;

export const IconContainer = styled.div`
  align-self: center;
  display: flex;
  margin-inline-start: 8px;
  height: 14px;
  width: 14px;
`;

export const BaseIcon = styled.i`
  font-size: 14px;
  animation: pl-spin 1s infinite linear;
  border-radius: 50%;

  @keyframes pl-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
