import React from "react";
import PropTypes from "prop-types";
import {
  Profile,
  ProfileMenuContainer,
  ProfileMenuDiv,
  Link,
  LoguotContainer,
} from "./style";
import Text from "components/common/Text";
import Avatar from "components/common/Avatar";

ProfileMenu.propTypes = {
  userImgSrc: PropTypes.string,
  userID: PropTypes.string,
  fullname: PropTypes.string,
  userName: PropTypes.string,
  handleOutsideClick: PropTypes.func,
};

const labels = {
  signOut: "התנתק",
  profile: "החשבון שלי",
};
function ProfileMenu(props) {
  const goToUrl = (url) => {
    window.location = url;
  };

  return (
    <ProfileMenuDiv onClick={(event) => event.stopPropagation()}>
      <Profile>
        <Avatar src={props.userImgSrc} size="40px" />
        <ProfileMenuContainer>
          <Text size="18px" bold={true}>
            {props.fullname}
          </Text>
          <Text size="13px">{props.userName}</Text>
          <Link
            color="#0c2867"
            textDecoration="underline"
            size="13px"
            onClick={() => goToUrl("https://app.powerlink.co.il/")}
          >
            {labels.profile}
          </Link>
        </ProfileMenuContainer>
      </Profile>
      <LoguotContainer withBorder onClick={props.logOut}>
        <Link
          size="14px"
          color="#081a43"
          onClick={() =>
            goToUrl(
              "https://app.powerlink.co.il/_common/webcontrols/logoff.aspx"
            )
          }
        >
          {labels.signOut}
        </Link>
      </LoguotContainer>
    </ProfileMenuDiv>
  );
}

export default ProfileMenu;
