import { default as axios } from "axios";

const getHeaders = () => {
  let header = { "Content-Type": "application/json" };

  return header;
};

export async function contactUs(params) {
  let url = `https://www.powerlink.co.il/api/form/contactus`;
  let headers = getHeaders();
  let response = await axios.post(url, params, { headers });
  return response.data;
}

export async function partners(params) {
  let url = `https://www.powerlink.co.il/api/form/partners`;
  let headers = getHeaders();
  let response = await axios.post(url, params, { headers });
  return response.data;
}

export async function getUserInfo() {
  let url = `https://www.powerlink.co.il/api/user/getinfo`;
  let headers = getHeaders();
  let response = await axios.get(url, { headers, withCredentials: true });
  return response.data;
}

export async function setCookie(params) {
  let url = `https://www.powerlink.co.il/api/user/createCookie`;
  let headers = getHeaders();
  let response = await axios.post(url, params, { headers });
  return response.data;
}
