import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ContentContainer,
  Container,
  Img,
  ImgContainer,
  TextContainer,
  Space,
  Link,
  IconContainer,
} from "./style";
import CookieImg from "images/cookies.png";
import Text from "components/common/Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

CookiesFooter.propTypes = {
  showBar: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func,
};

const isMobile = window.innerWidth < 768;

function CookiesFooter(props) {
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    if (!isMobile && !existsCookie()) {
      setIsShowing(props.showBar);
    }
  }, []);

  const existsCookie = () => {
    let cookies = document.cookie;
    const isExists = cookies.indexOf("__plcrm__") > -1;
    return isExists;
  };

  const handleCloseClick = () => {
    setIsShowing(false);
    if (props.onCloseClick) {
      props.onCloseClick();
    }
  };
  return (
    <>
      {isShowing ? (
        <Container>
          <ContentContainer>
            <ImgContainer>
              <Img src={`${process.env.REACT_APP_BASE_URL}/${CookieImg}`} />
            </ImgContainer>
            <TextContainer>
              <Text>
                אנחנו משתמשים בקבצי cookies באתר זה. במידה ותמשיך לגלוש הנך
                מסכים לשימוש זה
              </Text>
              <Space>|</Space>
              <Text>
                למידע נוסף ניתן לעיין{" "}
                <Link href="/privacypolicy">במדיניות הפרטיות</Link>
              </Text>
            </TextContainer>
          </ContentContainer>
          <IconContainer onClick={handleCloseClick}>
            <FontAwesomeIcon icon={faTimes} pull="left" size="m" />
          </IconContainer>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
}

export default CookiesFooter;
