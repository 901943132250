import React, { useState } from "react";
import {
  PartnersHeader,
  Container,
  FormBody,
  InputContainer,
  CaptchContainer,
  ErrorContainer,
} from "./style";
import TextInput from "components/common/TextInput";
import Card from "components/common/Card";
import TextAreaInput from "components/common/TextAreaInput";
import Text from "components/common/Text";
import Button from "components/common/Button";
import ReCAPTCHA from "react-google-recaptcha";
import { ERROR_MSG } from "constant/index";
import { partners } from "Services/Api/index";
import ThankYou from "components/common/ThankYou";

function Partners(props) {
  const [reCaptchaToken, setReCaptchaToken] = useState("");
  const [showReCaptchaErr, setReCaptchaErr] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [numOfEmp, setNumOfEmp] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companyDesc, setCompanyDesc] = useState("");
  const [mainProd, setMainProd] = useState("");
  const [firstNameErrMsg, setFirstNameErrMsg] = useState("");
  const [lastNameErrMsg, setLastNameErrMsg] = useState("");
  const [phoneErrMsg, setPhoneErrMsg] = useState("");
  const [emailErrMsg, setEmailErrMsg] = useState("");
  const [mainProdErrMsg, setMainProdErrMsg] = useState("");
  const [companyNameErrMsg, setCompanyNameErrMsg] = useState("");

  const handleSubmitClick = async () => {
    if (reCaptchaToken !== "") {
      let validFirstName = checkFirstName();
      let validCompanyName = checkCompanyName();
      let validPhone = checkPhone();
      let validMainProd = checkMainProd();
      let validEmail = checkEmail();
      let validLastName = checkLastName();

      if (
        validCompanyName &&
        validMainProd &&
        validPhone &&
        validFirstName &&
        validEmail &&
        validLastName
      ) {
        let params = {
          firstname: firstName,
          lastname: lastName,
          email: email,
          companyname: companyName,
          mainproduct: mainProd,
          website: companyWebsite,
          telephone1: phone,
          employeenumber: numOfEmp,
          address: address,
          companydescription: companyDesc,
          contactreturnpath: "ThankYou",
          reCaptchaToken: reCaptchaToken,
        };

        let response = await partners(params);

        if (response.status === 200) {
          setShowThankYou(true);
        } else {
          alert(ERROR_MSG.serverErrMsg);
        }
      }
    } else {
      setReCaptchaErr(true);
    }
  };

  const checkFirstName = () => {
    let valid = firstName !== "";

    if (!valid) {
      setFirstNameErrMsg(ERROR_MSG.required);
    } else {
      setFirstNameErrMsg("");
    }

    return valid;
  };

  const checkLastName = () => {
    let valid = lastName !== "";

    if (!valid) {
      setLastNameErrMsg(ERROR_MSG.required);
    } else {
      setLastNameErrMsg("");
    }

    return valid;
  };

  const checkCompanyName = () => {
    let valid = companyName !== "";

    if (!valid) {
      setCompanyNameErrMsg(ERROR_MSG.required);
    } else {
      setCompanyNameErrMsg("");
    }

    return valid;
  };

  const checkPhone = () => {
    let filled = phone !== "";
    let onlyNumbers = /^\d+$/.test(phone);

    if (!filled) {
      setPhoneErrMsg(ERROR_MSG.required);
    } else if (!onlyNumbers) {
      setPhoneErrMsg(ERROR_MSG.phoneMsg);
    } else {
      setPhoneErrMsg("");
    }

    return filled && onlyNumbers;
  };

  const checkEmail = () => {
    let filled = email !== "";
    let validEmail = email.includes("@");
    if (!filled) {
      setEmailErrMsg(ERROR_MSG.required);
    } else if (!validEmail) {
      setEmailErrMsg(ERROR_MSG.emailMsg);
    } else {
      setEmailErrMsg("");
    }

    return filled && validEmail;
  };

  const checkMainProd = () => {
    let valid = mainProd !== "";

    if (!valid) {
      setMainProdErrMsg(ERROR_MSG.required);
    } else {
      setMainProdErrMsg("");
    }

    return valid;
  };

  const handleReCaptchaChange = (response) => {
    if (response) {
      setReCaptchaToken(response);
      setReCaptchaErr(false);
    }
  };

  const handleEmployeeNumberChange = (number) => {
    if (number > 0) {
      setNumOfEmp(number);
    } else {
      setNumOfEmp(0);
    }
  };

  const handleFirstNameChange = (firstName) => {
    setFirstName(firstName);
  };

  const handleLstNameChange = (lastName) => {
    setLastName(lastName);
  };

  const handleCompanyNameChange = (companyname) => {
    setCompanyName(companyname);
  };

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };

  const handleEmailChange = (newEmail) => {
    setEmail(newEmail);
  };

  const handleWebsiteChange = (newWebsite) => {
    setCompanyWebsite(newWebsite);
  };

  const handlePhoneNumberChange = (newPhoneNumber) => {
    setPhone(newPhoneNumber);
  };

  const handleProductChange = (newProduct) => {
    setMainProd(newProduct);
  };

  const handleCompanyDescriptionChange = (newDescription) => {
    setCompanyDesc(newDescription);
  };

  return (
    <>
      {showThankYou ? (
        <ThankYou />
      ) : (
        <Container>
          <Card>
            <PartnersHeader>
              <Text lineHeight={"36px"} bold size={"32px"} color={"#2d2c4d"}>
                הרשם עכשיו לתכנית שותפים
              </Text>
            </PartnersHeader>
            <FormBody>
              <InputContainer>
                <TextInput
                  label={"שם פרטי"}
                  placeholder={""}
                  value={firstName}
                  required
                  showErrorMsg={firstNameErrMsg !== ""}
                  errorMsg={firstNameErrMsg}
                  onChange={handleFirstNameChange}
                ></TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  label={"שם משפחה"}
                  placeholder={""}
                  value={lastName}
                  required
                  showErrorMsg={lastNameErrMsg !== ""}
                  errorMsg={lastNameErrMsg}
                  onChange={handleLstNameChange}
                ></TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  label={"טלפון"}
                  placeholder={""}
                  value={phone}
                  required
                  showErrorMsg={phoneErrMsg !== ""}
                  errorMsg={phoneErrMsg}
                  onChange={handlePhoneNumberChange}
                ></TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  label={"שם חברה"}
                  placeholder={""}
                  value={companyName}
                  required
                  showErrorMsg={companyNameErrMsg !== ""}
                  errorMsg={companyNameErrMsg}
                  onChange={handleCompanyNameChange}
                ></TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  label={"כתובת"}
                  placeholder={""}
                  value={address}
                  onChange={handleAddressChange}
                ></TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  label={'דוא"ל'}
                  placeholder={""}
                  value={email}
                  required
                  showErrorMsg={emailErrMsg !== ""}
                  errorMsg={emailErrMsg}
                  onChange={handleEmailChange}
                ></TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  label={"מספר עובדים"}
                  placeholder={""}
                  type={"number"}
                  value={numOfEmp}
                  onChange={handleEmployeeNumberChange}
                ></TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  label={"אתר אינטרנט"}
                  placeholder={""}
                  value={companyWebsite}
                  onChange={handleWebsiteChange}
                ></TextInput>
              </InputContainer>
              <InputContainer
                rowStart={"span 1"}
                rowEnd={"span 1"}
                columnStart={"span 2"}
                columnEnd={"span 2"}
              >
                <TextInput
                  label={"המוצר המרכזי שהחברה מוכרת"}
                  required
                  placeholder={""}
                  value={mainProd}
                  showErrorMsg={mainProdErrMsg !== ""}
                  errorMsg={mainProdErrMsg}
                  onChange={handleProductChange}
                ></TextInput>
              </InputContainer>
              <InputContainer
                rowStart={"span 1"}
                rowEnd={"span 1"}
                columnStart={"span 2"}
                columnEnd={"span 2"}
              >
                <TextAreaInput
                  label="תיאור החברה"
                  placeholder={""}
                  value={companyDesc}
                  onChange={handleCompanyDescriptionChange}
                ></TextAreaInput>
              </InputContainer>
              <InputContainer
                rowStart={"span 1"}
                rowEnd={"span 1"}
                columnStart={"span 2"}
                columnEnd={"span 2"}
              >
                <CaptchContainer>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    onChange={handleReCaptchaChange}
                    onExpired={() => setReCaptchaToken("")}
                  />
                </CaptchContainer>
                <ErrorContainer display={showReCaptchaErr ? "flex" : "none"}>
                  <Text size={"14px"} color={"red"}>
                    {ERROR_MSG.recaptchMsg}
                  </Text>
                </ErrorContainer>
              </InputContainer>
              <InputContainer
                rowStart={"span 1"}
                rowEnd={"span 1"}
                columnStart={"span 2"}
                columnEnd={"span 2"}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    width={"100%"}
                    rounded
                    backgroundColor={"#3d78f8"}
                    color={"#fff"}
                    textSize={"20px"}
                    text={"הצטרף לתוכנית"}
                    onClick={handleSubmitClick}
                  ></Button>
                </div>
              </InputContainer>
            </FormBody>
          </Card>
        </Container>
      )}
    </>
  );
}

export default Partners;
