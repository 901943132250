import styled from "styled-components";

export const CardBase = styled.div`
width: auto;
height: 100%;
background-color: #fff;
max-width: 770px;
padding: 88px;
margin-left: auto;
margin-right: auto;
margin-bottom: 24px;
border-radius: 8px;
box-shadow: 0 15px 30px 0 rgba(48,54,77,.1);

@media screen and (max-width: 767px) {
    padding: 40px 10%;
}

`;

