import React, { useEffect, useState } from "react";
import {
  ContactHeader,
  Container,
  FormBody,
  InputContainer,
  CaptchContainer,
  ErrorContainer,
} from "./style";
import TextInput from "components/common/TextInput";
import Card from "components/common/Card";
import SelectInput from "components/common/SelectInput";
import TextAreaInput from "components/common/TextAreaInput";
import Text from "components/common/Text";
import Button from "components/common/Button";
import ReCAPTCHA from "react-google-recaptcha";
import { contactUs } from "Services/Api/index";
import {
  ERROR_MSG,
  SUBJECT_OPTIONS,
  SERVICE_DESCRIPTION,
} from "constant/index";
import ThankYou from "components/common/ThankYou";

function Contact(props) {
  const [reCaptchaToken, setReCaptchaToken] = useState("");
  const [showReCaptchaErr, setReCaptchaErr] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [nameErrMsg, setNameErrMsg] = useState("");
  const [companyNameErrMsg, setCompanyNameErrMsg] = useState("");
  const [phoneErrMsg, setPhoneErrMsg] = useState("");
  const [emailErrMsg, setEmailErrMsg] = useState("");
  const [subjectErrMsg, setSubjectErrMsg] = useState("");
  const [descriptionErrMsg, setDescriptionErrMsg] = useState("");
  const [sendingForm, setSendingForm] = useState(false);

  useEffect(() => {
    let urlParams = new URL(document.location).searchParams;
    const subjectValue = urlParams.get("subjectvalue");
    const message = urlParams.get("message");
    if (subjectValue) {
      let subjectOp = SUBJECT_OPTIONS.filter((op) => op.value == subjectValue);
      subjectOp[0] && setSubject(subjectOp[0]);
    }
    if (message && message < SERVICE_DESCRIPTION.length) {
      setDescription(SERVICE_DESCRIPTION[message]);
    }
  }, []);

  const handleSubmitClick = async () => {
    if (reCaptchaToken !== "") {
      setSendingForm(true);
      let validName = checkName();
      let validCompanyName = checkCompanyName();
      let validPhone = checkPhone();
      let validDescription = checkDescription();
      let validEmail = checkEmail();
      let validSubject = checkSubject();

      if (
        validCompanyName &&
        validDescription &&
        validPhone &&
        validName &&
        validEmail &&
        validSubject
      ) {
        let params = {
          contactleadcontacttype: subject.value,
          contactleadcontacttypevalue: subject.label,
          contactemailaddress1: email,
          contacttelephone1: phone,
          contactdescription: description,
          contactcompanyname: companyName,
          contactfirstname: name,
          leadsourcecode: "",
          mediacode: "2",
          keyword: "",
          contactreturnpath: "/ThankYou",
          reCaptchaToken: reCaptchaToken,
        };

        let response = await contactUs(params);

        if (response.status === 200) {
          setShowThankYou(true);
        } else {
          alert(ERROR_MSG.serverErrMsg);
          setSendingForm(false);
        }
      }
    } else {
      setReCaptchaErr(true);
    }
  };

  const checkName = () => {
    let valid = name !== "";

    if (!valid) {
      setNameErrMsg(ERROR_MSG.required);
    } else {
      setNameErrMsg("");
    }

    return valid;
  };

  const checkCompanyName = () => {
    let valid = companyName !== "";

    if (!valid) {
      setCompanyNameErrMsg(ERROR_MSG.required);
    } else {
      setCompanyNameErrMsg("");
    }

    return valid;
  };

  const checkPhone = () => {
    let filled = phone !== "";
    let onlyNumbers = /^\d+$/.test(phone);

    if (!filled) {
      setPhoneErrMsg(ERROR_MSG.required);
    } else if (!onlyNumbers) {
      setPhoneErrMsg(ERROR_MSG.phoneMsg);
    } else {
      setPhoneErrMsg("");
    }

    return filled && onlyNumbers;
  };

  const checkEmail = () => {
    let filled = email !== "";
    let validEmail = email.includes("@");
    if (!filled) {
      setEmailErrMsg(ERROR_MSG.required);
    } else if (!validEmail) {
      setEmailErrMsg(ERROR_MSG.emailMsg);
    } else {
      setEmailErrMsg("");
    }

    return filled && validEmail;
  };

  const checkSubject = () => {
    let valid = subject !== "";

    if (!valid) {
      setSubjectErrMsg(ERROR_MSG.required);
    } else {
      setSubjectErrMsg("");
    }

    return valid;
  };

  const checkDescription = () => {
    let valid = description !== "";

    if (!valid) {
      setDescriptionErrMsg(ERROR_MSG.required);
    } else {
      setDescriptionErrMsg("");
    }

    return valid;
  };

  const handleReCaptchaChange = (response) => {
    if (response) {
      setReCaptchaToken(response);
      setReCaptchaErr(false);
    }
  };

  const handleNameChange = (newName) => {
    setName(newName);
  };

  const handleCompanyNameChange = (newCompanyName) => {
    setCompanyName(newCompanyName);
  };

  const handlePhoneNumberChange = (newPhoneNumber) => {
    setPhone(newPhoneNumber);
  };

  const handleEmailChange = (newEmail) => {
    setEmail(newEmail);
  };

  const handleSubjectChange = (newSubject) => {
    setSubject(newSubject);
  };

  const handleDescriptionChange = (newDescription) => {
    setDescription(newDescription);
  };

  return (
    <>
      {showThankYou ? (
        <ThankYou />
      ) : (
        <Container>
          <Card>
            <ContactHeader>
              <Text lineHeight={"40px"} bold size={"40px"} color={"#2d2c4d"}>
                נשמח לשמוע מכם
              </Text>
              <Text size={"16px"} color={"#878787"}>
                השאירו לנו הודעה ונחזור אליכם בהקדם האפשרי
              </Text>
            </ContactHeader>
            <FormBody>
              <InputContainer>
                <TextInput
                  label={"שם"}
                  value={name}
                  required
                  placeholder={""}
                  showErrorMsg={nameErrMsg !== ""}
                  errorMsg={nameErrMsg}
                  onChange={handleNameChange}
                ></TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  label={"שם חברה"}
                  required
                  value={companyName}
                  placeholder={""}
                  showErrorMsg={companyNameErrMsg !== ""}
                  errorMsg={companyNameErrMsg}
                  onChange={handleCompanyNameChange}
                ></TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  label={"טלפון"}
                  required
                  value={phone}
                  placeholder={""}
                  showErrorMsg={phoneErrMsg !== ""}
                  errorMsg={phoneErrMsg}
                  onChange={handlePhoneNumberChange}
                ></TextInput>
              </InputContainer>
              <InputContainer>
                <TextInput
                  label={"כתובת אימייל"}
                  required
                  value={email}
                  placeholder={""}
                  showErrorMsg={emailErrMsg !== ""}
                  errorMsg={emailErrMsg}
                  onChange={handleEmailChange}
                ></TextInput>
              </InputContainer>
              <InputContainer
                rowStart={"span 1"}
                rowEnd={"span 1"}
                columnStart={"span 2"}
                columnEnd={"span 2"}
              >
                <SelectInput
                  options={SUBJECT_OPTIONS}
                  required
                  label={"נושא"}
                  selectedOption={subject}
                  placeholder={"בחרו נושא"}
                  onChange={handleSubjectChange}
                  showErrorMsg={subjectErrMsg !== ""}
                  errorMsg={subjectErrMsg}
                ></SelectInput>
              </InputContainer>
              <InputContainer
                rowStart={"span 1"}
                rowEnd={"span 1"}
                columnStart={"span 2"}
                columnEnd={"span 2"}
              >
                <TextAreaInput
                  label={"הודעה"}
                  required
                  value={description}
                  placeholder={""}
                  showErrorMsg={descriptionErrMsg !== ""}
                  errorMsg={descriptionErrMsg}
                  onChange={handleDescriptionChange}
                ></TextAreaInput>
              </InputContainer>
              <InputContainer
                rowStart={"span 1"}
                rowEnd={"span 1"}
                columnStart={"span 2"}
                columnEnd={"span 2"}
              >
                <CaptchContainer>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    onChange={handleReCaptchaChange}
                    onExpired={() => setReCaptchaToken("")}
                  />
                </CaptchContainer>
                <ErrorContainer display={showReCaptchaErr ? "flex" : "none"}>
                  <Text size={"14px"} color={"red"}>
                    {ERROR_MSG.recaptchMsg}
                  </Text>
                </ErrorContainer>
              </InputContainer>
              <InputContainer
                rowStart={"span 1"}
                rowEnd={"span 1"}
                columnStart={"span 2"}
                columnEnd={"span 2"}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    width={"150px"}
                    rounded
                    backgroundColor={"#3d78f8"}
                    textSize={"20px"}
                    color={"#fff"}
                    text={"שלח"}
                    onClick={handleSubmitClick}
                    isLoading={sendingForm}
                  ></Button>
                </div>
              </InputContainer>
            </FormBody>
          </Card>
        </Container>
      )}
    </>
  );
}

export default Contact;
