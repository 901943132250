import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Partners from "./pages/Partners";
import Login from "components/features/Login";
import Contact from "./pages/Contact";
import CookiesFooter from "./components/features/CookiesFooter";
import * as serviceWorker from "./serviceWorker";

if (document.getElementById("react-login")) {
  ReactDOM.render(
    React.createElement(Login, {}, null),
    document.getElementById("react-login")
  );
}
if (document.getElementById("react-partners")) {
  ReactDOM.render(
    React.createElement(Partners, {}, null),
    document.getElementById("react-partners")
  );
}
if (document.getElementById("react-contact")) {
  ReactDOM.render(
    React.createElement(Contact, {}, null),
    document.getElementById("react-contact")
  );
}
if (document.getElementById("react-cookie")) {
  ReactDOM.render(
    React.createElement(CookiesFooter, { showBar: true }, null),
    document.getElementById("react-cookie")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
