import React from "react";
import PropTypes from "prop-types";
import {
  BaseTextInput,
  BaseContainer,
  Header,
  AsteriskContainer,
  ErrorContainer,
} from "./style";
import Text from "components/common/Text";

TextInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  textColor: PropTypes.string,
  type: PropTypes.string,
  showErrorMsg: PropTypes.bool,
  errorMsg: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

TextInput.defaultProps = {
  type: "text",
};

function TextInput(props) {
  const handleChange = (event) => {
    if (props.onChange) {
      props.onChange(event.currentTarget.value);
    }
  };
  return (
    <BaseContainer>
      <Header>
        <Text color={props.textColor ? props.textColor : ""} bold size="14px">
          {props.label}
        </Text>
        {props.required ? (
          <AsteriskContainer display={props.showErrorMsg ? "flex" : "none"}>
            <Text color={"red"} size="14px">
              *
            </Text>
          </AsteriskContainer>
        ) : (
          <></>
        )}
      </Header>
      <BaseTextInput
        type={props.type}
        placeholder={props.placeholder}
        borderColor={props.showErrorMsg ? "red" : "#e6e8f0"}
        value={props.value}
        onChange={handleChange}
      ></BaseTextInput>
      <ErrorContainer display={props.showErrorMsg ? "flex" : "none"}>
        <Text color={"red"} size="10px">
          {props.errorMsg}
        </Text>
      </ErrorContainer>
    </BaseContainer>
  );
}

export default TextInput;
