import styled from "styled-components";
import Menu from '@material-ui/core/Menu';

export const BaseStyle = styled(Menu)`
.MuiPaper-root {
    direction: rtl;
    margin-top: 38px;
}

.MuiListItem-button:hover {
    text-decoration: none;
    background-color: transparent;
}

.MuiList-padding {
padding-top: 0px;
padding-bottom: 0px;
}
.MuiPaper-elevation8 {
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.05), 0 4px 3px 0 rgba(0,0,0,0.05);
}
`;