import React from 'react';
import PropTypes from 'prop-types';
import { CardBase } from './style';

Card.propTypes = {

};

Card.defaultProps = {

};

function Card(props) {


   
    return (
        <CardBase
        >
            {props.children}
        </CardBase>
    );
}

export default Card;