import styled from "styled-components";
import { default as avatar_material_ui } from "@material-ui/core/Avatar";

export const BaseAvatar = styled(avatar_material_ui)`
  && {
    font-size: ${(props) => props.fontSize}px;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    background-color: transparent;
  }
`;
