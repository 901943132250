import styled from "styled-components";
import Select from "@material-ui/core/Select";

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const BaseSelect = styled(Select)`
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${(props) => props.borderColor} !important;
  }
  .MuiSelect-iconOutlined {
    right: initial;
    left: 8px;
  }
  .MuiOutlinedInput-input {
    padding: 9px !important;
  }
`;
export const Header = styled.label`
  margin-bottom: 5px;
  display: flex;
`;

export const SelectIconContainer = styled.div`
  display: flex;
  margin-inline-start: 10px;
`;

export const Icon = styled.i`
  cursor: pointer;
`;

export const AsteriskContainer = styled.div`
  display: ${(props) => props.display};
  justify-content: center;
  align-items: center;
`;

export const ErrorContainer = styled.div`
  dispaly: ${(props) => props.display};
`;
