import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  SelectContainer,
  Header,
  BaseSelect,
  AsteriskContainer,
} from "./style";
import Text from "components/common/Text";

SelectInput.propTypes = {
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  showErrorMsg: PropTypes.bool,
  errorMsg: PropTypes.string,
  selectedOption: PropTypes.object, // {label: '', value: ''}
};
SelectInput.defaultProps = {
  required: false,
  selectedOption: undefined,
};

function SelectInput(props) {
  const [selectedValue, setSelectedValue] = useState({});

  useEffect(() => {
    if (props.selectedOption) {
      setSelectedValue(props.selectedOption);
    }
  }, [props.selectedOption]);

  const handleOnChange = (selectedObject) => {
    setSelectedValue(selectedObject);
    props.onChange(selectedObject);
  };

  return (
    <SelectContainer>
      <Header>
        <Text bold size="14px">
          {props.label}
        </Text>
        {props.required ? (
          <AsteriskContainer display={props.showErrorMsg ? "flex" : "none"}>
            <Text color={"red"} size="14px">
              *
            </Text>
          </AsteriskContainer>
        ) : (
          <></>
        )}
      </Header>
      <BaseSelect
        onChange={handleOnChange}
        id="select"
        native={true}
        defaultValue="none"
        variant="outlined"
        borderColor={props.showErrorMsg ? "red" : "#e6e8f0"}
        value={selectedValue.value}
      >
        <option value="none">{props.placeholder}</option>
        {props.options.map((option) => {
          return <option value={option.value}>{option.label}</option>;
        })}
      </BaseSelect>
    </SelectContainer>
  );
}

export default SelectInput;
