import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    flex-direction: row-reverse;
    direction: rtl;
    background-color: #fff;
    color: #000000;
    height: 40px;
    box-shadow: 0 -2px 16px rgba(20,34,180,.1);
    position: fixed;
    width: 100%;
    z-index: 999;
    bottom: 0;
    align-items: center;
`;

export const ContentContainer = styled.div`
    max-width: 70%;
    display: flex;
    margin: 0 auto;
`;

export const Img = styled.img`
    width: 25px;
    height: 25px;
    margin-inline-start: 5px;
`;

export const ImgContainer = styled.span`
    margin-inline-end: 10px;
    display: flex;
`;

export const TextContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Space = styled.div`
    margin-right: 10px;
    margin-left: 10px;
`;

export const Link = styled.a`
    text-decoration: underline;
    color: #000000;
`;

export const Icon = styled.i`
    font-size: 13px;
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-end: 12px;
    position:absolute;
`;