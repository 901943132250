export const ERROR_MSG = {
  required: "זהו שדה חובה",
  emailMsg: "כתובת המייל אינה תקינה",
  phoneMsg: "מספר הטלפון אינו תקין",
  recaptchMsg: "*יש לסמן שאינך רובוט",
  serverErrMsg: "שגיאה! נא מלאו את הפרטים בשנית",
};

export const SUBJECT_OPTIONS = [
  { label: "שאלה לגבי המוצר", value: 1 },
  { label: "שאלה למכירות/מחיר", value: 2 },
  { label: "תמיכה טכנית", value: 3 },
  { label: "ממשקים/API", value: 4 },
  { label: "שותפים עסקיים", value: 5 },
  { label: "כנס לקוחות", value: 6 },
  { label: "אבטחת מידע והגנת פרטיות", value: 7 },
  { label: "רכישת חבילת SMS", value: 12 },
  { label: "רכישת שירותים", value: 13 },
];

export const SUCCESS_MSG = {
  primaryHeader: "הפרטים נשלחו בהצלחה!",
  secondaryHeader: "נציגי החברה יצרו איתך קשר בהקדם.",
};

export const SERVICE_DESCRIPTION = [
  "אני מעוניין לרכוש התממשקות למרכזיית ענן",
  "אני מעוניין לרכוש חבילת תמיכה מתקדמת",
  "אני מעוניין לרכוש חבילת הדרכה",
  "אני מעוניין לרכוש שירותים מותאמים אישית",
];
