import React, { useState } from "react";
import PropTypes from "prop-types";
import { BaseStyle } from "./style";

Menu.propTypes = {
  buttonEl: PropTypes.element,
  width: PropTypes.string,
  onOpenMenu: PropTypes.func,
  onCloseMenu: PropTypes.func,
};

Menu.defaultProps = {
  direction: null,
};

function Menu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isActive, setIsActive] = useState(false);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
    setIsActive(true);
    if (props.onOpenMenu) {
      props.onOpenMenu();
    }
    event.stopPropagation();
  }

  function handleMenuClose(e) {
    e.stopPropagation();
    closeMenu();
  }

  function closeMenuItemClick(e) {
    if (e.target.nodeName !== "INPUT" && e.target.nodeName !== "I") {
      closeMenu();
    }
    e.stopPropagation();
  }

  function closeMenu() {
    setIsActive(false);
    setAnchorEl(null);
    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
  }

  const MenuButtonTagName = props.buttonEl.type;
  return (
    <>
      <MenuButtonTagName
        {...props.buttonEl.props}
        onClick={handleClick}
        isActive={isActive}
      ></MenuButtonTagName>
      <BaseStyle
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div onClick={closeMenuItemClick}>{props.children}</div>
      </BaseStyle>
    </>
  );
}

export default Menu;
