import styled from "styled-components";

export const BaseTextInput = styled.input`
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 5px;
  padding: 8px;
  background-color: #fff;
  line-height: 1.42857143;
  font-size: 14px;

  ::placeholder {
    color: #b9bdce;
    opacity: 1;
  }
`;

export const BaseContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.label`
  margin-bottom: 5px;
  display: flex;
`;
export const AsteriskContainer = styled.div`
  display: ${(props) => props.display};
  justify-content: center;
  align-items: center;
`;

export const ErrorContainer = styled.div`
  display: ${(props) => props.display};
`;
