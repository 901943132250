import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BaseButton, IconContainer, BaseIcon } from "./style";

Button.propTypes = {
  width: PropTypes.string,
  rounded: PropTypes.bool,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  textSize: PropTypes.string,
  isLoading: PropTypes.bool,
  fontWeight: PropTypes.string,
};

Button.defaultProps = {
  isLoading: false,
  fontWeight: "",
};

function Button(props) {
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);

  useEffect(() => {
    setShowLoadingIcon(props.isLoading);
  }, [props.isLoading]);

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <BaseButton
      textSize={props.textSize}
      width={props.width ? props.width : "100%"}
      color={props.color ? props.color : ""}
      backgroundColor={props.backgroundColor ? props.backgroundColor : ""}
      radius={props.rounded ? "50px" : "4px"}
      onClick={handleClick}
      fontWeight={props.fontWeight}
    >
      {props.text}
      {showLoadingIcon && (
        <IconContainer>
          <BaseIcon className={"icon icon-spinner"} />
        </IconContainer>
      )}
    </BaseButton>
  );
}

export default Button;
