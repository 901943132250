import styled from "styled-components";

export const ContactHeader = styled.div`
  margin-bottom: 19px;
  text-align: center;

  & > *:first-child {
    margin-bottom: 10px;
  }
`;

export const PartnersHeader = styled.div`
  margin-bottom: 19px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1100px;
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
  direction: rtl;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin-right: 8px;
    margin-left: 8px;
  }
`;

export const FormBody = styled.div`
  display: grid;
  padding-top: 24px;
  flex-direction: column;
  justify-items: stretch;
  grid-auto-columns: 1fr;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  grid-column-gap: 16px;
  grid-row-gap: 24px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  grid-row-start: ${(props) => props.rowStart};
  grid-row-end: ${(props) => props.rowEnd};
  grid-column-start: ${(props) => props.columnStart};
  grid-column-end: ${(props) => props.columnEnd};
  box-sizing: border-box;

  @media screen and (max-width: 767px) {
    grid-row-start: span 1;
    grid-row-end: span 1;
    grid-column-start: span 2;
    grid-column-end: span 2;
  }
`;

export const CaptchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ErrorContainer = styled.div`
  display: ${(props) => props.display};
  justify-content: center;
  align-items: center;
`;
