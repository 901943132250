import React from 'react';
import PropTypes from 'prop-types';
import { BaseText } from './style';



Text.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string, // string with px/em/% Not small/medium/etc..
  bold: PropTypes.bool,
  lineHeight: PropTypes.string
};

Text.defaultProps = {
  size: '14px',
  bold: false,
  lineHeight: ''
};

function Text(props) {
  

  return (
    <BaseText
      fontSize={props.size}
      color={props.color}
      fontWeight={props.bold ? 'bold' : ''}
      lineHeight={props.lineHeight}
    >
      {props.children}
    </BaseText>
  );
}

const areEqual = (prevProps, nextProps) => {
  return prevProps.color === nextProps.color && prevProps.size === nextProps.size
          && prevProps.lineHeight === nextProps.lineHeight && prevProps.bold === nextProps.bold
}

export default React.memo(Text,areEqual);
