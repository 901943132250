import React from "react";
import PropTypes from "prop-types";
import { BaseAvatar } from "./style";

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.string,
};

Avatar.defaultProps = {
  size: "12px",
};

function Avatar(props) {
  return <BaseAvatar size={props.size} src={props.src} />;
}

export default Avatar;
